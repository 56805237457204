import React, { PropTypes } from 'react';

const FaqSearch = ({ onChange }) => (
  <div className="faq-search">
    <form>
      <div className="field field--search">
        <input placeholder="Search FAQs" onChange={onChange} type="text" />
        <button className="button button--field"><img alt="Search" src="//hedson.dev:3000/wp-content/themes/hedson/dist/images/input-search-icon.svg" /></button>
      </div>
    </form>
  </div>
);

FaqSearch.propTypes = {
  onChange: PropTypes.func,
};

export default FaqSearch;
