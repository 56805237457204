import React from 'react';
import postPropType from '../../prop-types/post';

const PostListEventItem = ({ post }) => (
  <article
    className={`
      post-list__item
      events-calendar__item
      ${post.tags.map(({ name }) => `post-list__item--filter-${name.replace(/ /g, '-')}`).join(' ')}
    `}
  >
    <div className="events-calendar__item-inner">
      {
        !post.image ? null
        : <div className="events-calendar__image"><img alt={post.title} src={post.image} /></div>
      }
      <div className="events-calendar__content">
        <div className="events-calendar__title">{post.title}</div>
        <div className="events-calendar__date-time">
          <div className="events-calendar__date">{post.date}</div>
          <div className="events-calendar__time">{post.time}</div>
        </div>
        <div
          className="events-calendar__description"
          dangerouslySetInnerHTML={{ __html: post.text }}
        />
      </div>
    </div>
  </article>
);



PostListEventItem.propTypes = { post: postPropType };

export default PostListEventItem;
