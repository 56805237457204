import React, { PropTypes } from 'react';
import postPropType from '../../prop-types/post';
import PostImage from './PostImage';
import PostContent from './PostContent';
import PostTags from './PostTags';

const PostListImageItem = ({ post, setFilter }) =>
  <article
    className={`
      post-list__item
      ${post.tags.map(({ name }) => `post-list__item--filter-${name.replace(/ /g, '-')}`).join(' ')}
    `}
  >
    <div className="post-list__item-inner">
      <PostImage title={post.title} image={post.preview_image.url} />
      <div className="post-list__text-content-wrapper">
        <PostContent
          post={{
            title: post.title,
            text: post.description,
          }}
        />
        {
          !post.file || !post.file.url ? null
          :
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={post.file.url}
            className="button button--link post-list__download-button"
          >Download</a>
        }
        <footer className="post-list__footer">
          <PostTags tags={post.tags} setFilter={setFilter} />
        </footer>
      </div>
    </div>
  </article>;

PostListImageItem.propTypes = {
  post: postPropType,
  setFilter: PropTypes.func.isRequired,
};

export default PostListImageItem;
