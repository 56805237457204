/* global document */
import Flickity from 'flickity';

const Hero = (el) => {
  const ctas = Array.from(el.querySelectorAll('.hero__cta'));
  const instance = new Flickity(el, {
    setGallerySize: false,
    cellSelector: '.hero__slide',
    imagesLoaded: true,
    wrapAround: true,
    autoPlay: parseInt(el.getAttribute('data-autoplay-interval'), 10),
  });
  instance.on('settle', () => {
    // ctas[instance.selectedIndex].classList.add('hero__cta--active');
  });
  instance.on('select', () => {
    ctas.forEach(cta => cta.classList.remove('hero__cta--active'));
    ctas[instance.selectedIndex].classList.add('hero__cta--active');
  });

  ctas[0].classList.add('hero__cta--active');
};

export default () => {
  Array.from(document.querySelectorAll('.hero')).forEach((el) => {
    Hero(el);
  });
};
