/* global document, window */
import throttle from 'lodash.throttle';
import isInViewport from '../../utils/inviewport';

const CountItem = (el) => {
  const ease = 0.12;
  const duration = 5;
  const target = parseInt(el.getAttribute('data-count-to'), 10);
  const domEl = el;
  let currentValue = 0;
  let raf = null;

  const stopAnimate = () => {
    window.cancelAnimationFrame(raf);
    raf = null;
  };

  const tick = () => {
    currentValue += ((target - currentValue) * ease) / duration;
    domEl.innerHTML = parseInt(currentValue, 10);
    if (raf && Math.ceil(currentValue) < target) {
      raf = window.requestAnimationFrame(tick);
    } else {
      stopAnimate();
      domEl.innerHTML = target;
    }
  };

  const startAnimate = () => {
    raf = window.requestAnimationFrame(tick);
  };

  startAnimate();
};

const Counter = (el) => {
  const domEl = el;
  const items = Array.from(el.querySelectorAll('.counter__number'));
  let scrolledThrottled;

  const start = () => {
    items.forEach(item => CountItem(item));
  };

  const scrolled = () => {
    if (isInViewport(domEl)) {
      window.removeEventListener('scroll', scrolledThrottled);
      start();
    }
  };

  scrolledThrottled = throttle(scrolled, 100);
  setTimeout(() => window.addEventListener('scroll', scrolledThrottled), 500);

  // Start the counter if the counter is in view from the beginning
  // Add some extra time for this to allow the layout to finish/images to load
  setTimeout(() => isInViewport(domEl) ? start() : null, 1500);
};

export default () => {
  const modules = Array.from(document.querySelectorAll('.counter'));
  modules.forEach((module) => {
    Counter(module);
  });
};
