import React, { PropTypes } from 'react';
import classnames from 'classnames';


class FaqItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    const { question, answer, links } = this.props.data;
    return (
      <div
        className={classnames({
          'faq-item': 'true',
          'faq-item--expanded': this.state.expanded,
        })}
        onClick={() => {
          this.setState({ expanded: !this.state.expanded });
        }}
      >
        <div className="faq-item__question">
          {question}
        </div>
        <div className="faq-item__expandable">
          <div
            className="faq-item__answer"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
          <div className="faq-item__links">
            {
              links.map((link, i) =>
                (
                  <a key={i} href={link.href} className="button button--link">{link.text}</a>
                ),
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

FaqItem.propTypes = {
  data: PropTypes.object,
};

export default FaqItem;
