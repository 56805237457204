/* global document */
import React from "react";
import ReactDOM from "react-dom";
import Sharer from "./Sharer";

export default () => {
  const domEl = document.querySelector(".menu__item.icon-share");
  if (!domEl) {
    return;
  }
  const title = domEl.querySelector("a").innerText;
  if (domEl) {
    ReactDOM.render(<Sharer title={title} />, domEl);
  }
};
