import React, { PropTypes } from 'react';
import classnames from 'classnames';

const FaqTag = ({ tag, selected, onClicked }) => (
  <button
    className={classnames({
      'faq-tag': 'true',
      'faq-tag--selected': selected,
    })}
    onClick={() => {
      onClicked(tag);
    }}
  >
    {tag.name}
  </button>
);

FaqTag.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string,
  }),
  selected: PropTypes.bool,
  onClicked: PropTypes.func,
};

export default FaqTag;
