/* global document */
import Isotope from 'isotope-layout';

export default () => {
  const modulesContainers = Array.from(document.querySelectorAll('.events-calendar'));
  modulesContainers.forEach(moduleContainer => new Isotope(moduleContainer, {
    itemSelector: '.events-calendar__item',
    layoutMode: 'fitRows',
  }));
};
