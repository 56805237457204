/* global google, window, document */
import React from 'react';
import R from 'ramda';
import ReactDOM from 'react-dom';
import ContactsContainer from './ContactsContainer';

export default () => {
  const components = document.querySelectorAll('.find-contacts-component');

  const mapsApiReady = () => {
    google.maps.Map.prototype.setCenterWithOffset = function (latlng, offsetX, offsetY) {
      const map = this;
      const ov = new google.maps.OverlayView();
      ov.onAdd = function () {
        const proj = this.getProjection();
        const aPoint = proj.fromLatLngToContainerPixel(latlng);
        aPoint.x += offsetX;
        aPoint.y += offsetY;
        map.panTo(proj.fromContainerPixelToLatLng(aPoint));
      };
      ov.draw = () => {};
      ov.setMap(this);
    };
    R.forEach((component) => {
      ReactDOM.render(<ContactsContainer />, component);
    }, components);
  };

  const initMapApi = () => {
    if (window.google && window.google.maps) {
      mapsApiReady();
      return;
    }
    const global = `nd_const${Math.round(Math.random() * 99999)}`;
    window[global] = mapsApiReady;
    const script = document.createElement('script');
    script.onload = () => delete window[global];
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?callback=${global}&key=AIzaSyBVWuJoq4Z5v66E2O-jvDQ7dbExmux3CzI`;
    document.body.appendChild(script);
  };

  if (components.length) {
    initMapApi();
  }
};
