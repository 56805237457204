/* global document */

const generateMarketSegmentInPageNav = () => {
  const container = document.querySelector(".in-page-navbar--auto");
  if (!container) return false;
  let html = "";
  Array.from(
    document.querySelectorAll(".product-list__segment")
  ).forEach(el => {
    html += `
      <div class="in-page-navbar__link">
        <a href="#${el.id}">${el.getAttribute("data-title")}</a>
      </div>`;
  });
  container.innerHTML = html;
  return true;
};

export default () => {
  const navButton = document.querySelector(".site-header__menu-toggle");
  const navExpandButtons = Array.from(
    document.querySelectorAll(".menu__expand")
  );
  const mobileSearchToggleButton = document.querySelector(
    ".site-header__search-toggle"
  );

  const navButtonClicked = e => {
    // TODO: Maybe make sure the menu is actually open and not just randomly toggle?
    const { target } = e;
    if (document.body.classList.contains("nav-open")) {
      document.body.classList.remove("nav-open");
      target.classList.remove("site-header__menu-toggle--close");
    } else {
      document.body.classList.add("nav-open");
      target.classList.add("site-header__menu-toggle--close");
      document.body.classList.remove("mobile-search-open");
    }
  };

  const navExpandButtonClicked = e => {
    e.target.parentNode.classList.toggle("menu__item--expanded");
  };

  const mobileSearchButtonClicked = e => {
    if (document.body.classList.contains("mobile-search-open")) {
      document.body.classList.remove("mobile-search-open");
    } else {
      document.body.classList.add("mobile-search-open");
    }
  };

  navExpandButtons.forEach(button => {
    button.addEventListener("click", navExpandButtonClicked);
  });

  if (navButton) {
    navButton.addEventListener("click", navButtonClicked);
  }

  if (document.body.classList.contains("single-market_segments")) {
    generateMarketSegmentInPageNav();
  }

  if (mobileSearchToggleButton) {
    mobileSearchToggleButton.addEventListener(
      "click",
      mobileSearchButtonClicked
    );
  }
};
