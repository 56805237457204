import React, { PropTypes } from 'react';
import classnames from 'classnames';
import FaqSearch from './FaqSearch';
import FaqList from './FaqList';
import FaqTag from './FaqTag';
import FaqLinks from './FaqLinks';

class Faqs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagsFilter: [...props.selectedTags],
      searchFilter: '',
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleTagClicked = this.handleTagClicked.bind(this);
  }

  handleSearchChange(arg) {
    this.setState({
      searchFilter: arg.target.value.toLowerCase(),
    });
  }

  handleTagClicked(clickedTag) {
    this.setState({
      tagsFilter: this.state.tagsFilter.indexOf(clickedTag.term_id) >= 0 ?
        this.state.tagsFilter.filter(tagId => tagId !== clickedTag.term_id) :
          [...this.state.tagsFilter, clickedTag.term_id],
    });
  }

  filter(categories) {
    const { tagsFilter, searchFilter } = this.state;
    let result = categories;

    if (tagsFilter.length) {
      result = result.reduce((memo, category) => {
        const posts = category.posts.filter(post =>
          post.tags.some(tag => tagsFilter.indexOf(tag.term_id) > -1),
        );
        if (posts.length) {
          memo.push(Object.assign({}, category, { posts }));
        }
        return memo;
      }, []);
    }

    if (searchFilter.length) {
      result = result.reduce((memo, category) => {
        const posts = category.posts.filter(post =>
          [post.category, post.question, post.answer].some(value =>
            value.toLowerCase().indexOf(searchFilter) > -1,
          ),
        );
        if (posts.length) {
          memo.push(Object.assign({}, category, { posts }));
        }
        return memo;
      }, []);
    }
    return result;
  }

  render() {
    const filteredData = this.filter(this.props.data);

    return (
      <div
        className={classnames({
          faqs: true,
          'faqs--loading': this.props.loading,
        })}
      >
        <div className="faq-tags">
          {
            this.props.tags.map((tag, i) =>
              <FaqTag
                key={i}
                tag={tag}
                onClicked={this.handleTagClicked}
                selected={this.state.tagsFilter.indexOf(tag.term_id) >= 0}
              />,
            )
          }
        </div>
        <FaqSearch onChange={this.handleSearchChange} />
        <FaqLinks data={this.props.data} filteredData={filteredData} />
        <FaqList data={filteredData} />
      </div>
    );
  }
}

Faqs.propTypes = {
  data: PropTypes.arrayOf(
    React.PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
      section: PropTypes.string,
      tags: PropTypes.array,
    }),
  ),
  tags: PropTypes.array,
  selectedTags: PropTypes.array,
  loading: PropTypes.bool,
};

export default Faqs;
