/* global document */
import Flickity from 'flickity';

const Carousel = (el) => {
  const instance = new Flickity(el, {
    adaptiveHeight: true,
    setGallerySize: true,
    cellSelector: '.carousel__slide',
    imagesLoaded: true,
    wrapAround: true,
  });

  return instance;
};

export default () => {
  Array.from(document.querySelectorAll('.carousel__slides')).forEach((el) => {
    Carousel(el);
  });
};
