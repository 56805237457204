import React, { PropTypes } from 'react';
import imagesLoaded from 'imagesloaded';

const placeHolder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAgCAYAAABQISshAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADpJREFUeNrszzERAAAIBCC1f+c3hYsHDegk9cHUEyIiIiIiIiIiIiIiIiIiIiIiIiIiIiIi11YAAQYAUFgDPVZvp5cAAAAASUVORK5CYII=';

class PostImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.image) {
      imagesLoaded(this.domEl, () => this.setState({ loading: false }));
    }
  }

  renderImage() {
    const { image, title } = this.props;
    return (
      <img
        ref={(c) => { this.domEl = c; }}
        alt={title}
        className="post-list__image"
        src={this.state.loading ? placeHolder : image}
      />
    );
  }

  render() {
    const { image, url, target = '_self' } = this.props;
    return (
      !image ? null
      : url
        ? <a target={target} href={url}>{this.renderImage()}</a>
        : this.renderImage()
    );
  }
}

PostImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
};

export default PostImage;
