import React, { PropTypes } from "react";
import ContactsFilterSelect from "./ContactsFilterSelect";

const ContactsFilter = ({
  filter,
  countries,
  // Not used anymore!
  // marketSegments,
  // productSegments,
  onChange,
}) => (
  <div className="find-contacts__filter">
    <ContactsFilterSelect
      options={countries.map((country) => ({ value: country, label: country }))}
      selectedValue={filter.country}
      label={"Country"}
      onChange={(val) => onChange("country", val)}
    />
    {/* <ContactsFilterSelect
      options={
        marketSegments.map(segment => ({ value: segment.id.toString(), label: segment.name }))
      }
      selectedValue={filter.market_segments}
      label={'Market segment'}
      onChange={val => onChange('market_segments', val)}
    />
    <ContactsFilterSelect
      optionsOld={
        marketSegments.reduce((memo, { product_segments, name }) =>
          memo.concat(product_segments.map(segment =>
            ({ value: segment.id.toString(), label: `${name} - ${segment.name}` }),
          ))
        , [])
      }
      options={
        !filter.market_segments
        ? productSegments.map(segment => ({ value: segment.id.toString(), label: segment.name }))
        : marketSegments
        .filter(({ id }) => id.toString() === filter.market_segments)
        .reduce((memo, { product_segments }) => memo.concat(
          product_segments.map(segment => ({ value: segment.id.toString(), label: segment.name })),
        ), [])
      }
      selectedValue={filter.product_segments}
      label={'Product segment'}
      onChange={val => onChange('product_segments', val)}
    /> */}
  </div>
);

ContactsFilter.propTypes = {
  countries: PropTypes.array,
  // marketSegments: PropTypes.array,
  // productSegments: PropTypes.array,
  filter: PropTypes.object,
  onChange: PropTypes.func,
};

export default ContactsFilter;
