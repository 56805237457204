/* global window, document */
import React, { PropTypes } from 'react';

const Popup = ({ title }) => {
  const url = encodeURI(window.location.href);

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
  const linkedInUrl = `https://www.linkedin.com/shareArticle?url=${url}&mini=true`;
  return (
    <div className="sharer__content">
      <h2>{title}</h2>
      <a rel="noopener noreferrer" target="_blank" href={facebookUrl}>Facebook</a>
      <a rel="noopener noreferrer" target="_blank" href={twitterUrl}>Twitter</a>
      <a rel="noopener noreferrer" target="_blank" href={linkedInUrl}>LinkedIn</a>
    </div>
  );
};

Popup.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Popup;
