/* global window, document */
import React, { PropTypes } from "react";
import classnames from "classnames";
import {
  pickBy,
  identity,
  toPairs,
  compose,
  all,
  prop,
  map,
  pick,
  head,
  values,
} from "ramda";
import ContactsFilter from "./ContactsFilter";
import ContactsMap from "./ContactsMap";
import ContactsDetailPanel from "./ContactsDetailPanel";

// getFilterTuple :: { a: b, c: d } -> [[a, b], [c, d]]
const getFilterTuple = compose(toPairs, pickBy(identity));
// prepareContactForFilter :: ()
const prepareContactForFilter = compose(
  map(map(map((val) => val.toString()))),
  map(map((val) => (typeof val !== "object" ? [val] : val))),
  pick
);

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      filter: this.props.activeFilter,
      selectedContact: null,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleContactClicked = this.handleContactClicked.bind(this);
  }

  getFilteredContacts() {
    // TODO: Wow this turned into a real mess!
    const { filter } = this.state;
    const setFilters = getFilterTuple(filter);
    const preparedContacts = this.props.data.contacts.map(
      prepareContactForFilter(map(([key]) => key, [["id"], ...setFilters]))
    );
    const newFiltered = preparedContacts
      .filter((contact) =>
        setFilters.length < 1
          ? true
          : all(
              identity,
              setFilters.map(
                ([key, value]) =>
                  prop(key, contact) &&
                  values(prop(key, contact)).indexOf(value) > -1
              )
            )
      )
      .map(({ id }) => parseInt(head(id), 10));
    return this.props.data.contacts.filter(
      ({ id }) => newFiltered.indexOf(id) > -1
    );
  }

  handleFilterChange(filterKey, value) {
    this.setState({
      filter: Object.assign(this.state.filter, {
        [filterKey]: value ? value.value : null,
      }),
      selectedContact: null,
    });
  }

  handleContactClicked(contact) {
    this.setState({ selectedContact: contact });
  }

  render() {
    const { countries } = this.props.data;
    const filteredContacts = this.getFilteredContacts();

    return (
      <div className="container">
        <div className="find-contacts">
          <ContactsFilter
            countries={countries || [] ? countries : values(countries)}
            // marketSegments={market_segments}
            // productSegments={product_segments}
            filter={this.state.filter}
            onChange={this.handleFilterChange}
          />
          <ContactsMap
            contacts={filteredContacts}
            onContactClick={this.handleContactClicked}
          />
          <ContactsDetailPanel
            unsetContact={() => this.handleContactClicked(null)}
            contact={this.state.selectedContact}
          />
          <div
            className={classnames({
              "find-contacts__overlay": true,
              "find-contacts__overlay--visible": !filteredContacts.length,
            })}
          >
            <div className="find-contacts__overlay-text">
              No contacts found.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Contacts.propTypes = {
  data: PropTypes.object,
  activeFilter: PropTypes.object,
};

Contacts.defaultProps = {
  data: [],
  activeFilter: [],
};

export default Contacts;
