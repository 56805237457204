/* global document, TRANSLATIONS */

const maxItems = 5;
const collapsedClass = 'technical-specification--collapsed';
const faderClass = 'technical-specification__bottom-fader';
const collapseButtonClass = 'technical-specification__collapse-button';

// fn for resetting the collapsed state
const resetShowMoreStuff = ($, el, fn) => () => $(el)
.append(
  $('<button />')
  .addClass(`button--link ${collapseButtonClass}`)
  .text(TRANSLATIONS['Collapse list'])
  .on('click', () => [fn($, el), $(`.${collapseButtonClass}`).remove()]),
)
.removeClass(collapsedClass)
.find(`.${faderClass}`)
.remove();

// fn for setting the collapsed state
const addShowMoreButton = ($, el) => $(el)
.addClass(collapsedClass)
.append(
$('<div />')
.addClass(faderClass)
.append(
  $('<button />')
  .addClass(`button--link ${faderClass}-button`)
  .text(TRANSLATIONS['Expand list'])
  .on('click', resetShowMoreStuff($, el, addShowMoreButton)),
),
);

// fn which checks if the passed el has more than maxItems items, and
// if so: collpases it!
const TechSpec = $ => el =>
($(el).find('.technical-specification__item').length > maxItems)
? addShowMoreButton($, el)
: true;

export default $ => Array
.from(document.querySelectorAll('.technical-specification'))
.map(TechSpec($));
