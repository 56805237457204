/* global window */
import React from 'react';
import Faqs from './Faqs';
import Api from '../../utils/api';
import getParams from '../../utils/getparams';

const tagFromQueryString = getParams(window.location.search).tags;

class FaqsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      tags: [],
      selectedTags: [],
    };
  }

  componentDidMount() {
    Api.get('faqs').then((result) => {
      this.setState({
        loading: false,
        data: result.categories,
        tags: result.tags,
        selectedTags: (tagFromQueryString ?
        result.tags.filter(tag => tag.name.toLowerCase() === tagFromQueryString.toLowerCase()) : [])
        .map(tag => tag.term_id),
      });
    });
  }

  render() {
    return (
      !this.state.loading ?
        <Faqs data={this.state.data} tags={this.state.tags} selectedTags={this.state.selectedTags} loading={this.state.loading} /> : null
    );
  }
}

export default FaqsContainer;
