import React, { PropTypes } from 'react';
import classnames from 'classnames';
import postTagPropType from '../../prop-types/post-tag';

const PostListFilterItem = ({ name, onClick, active, extraClasses }) =>
  <a
    onClick={() => onClick(name)}
    className={classnames({
      'post-list__filter-item': 'true',
      'post-list__filter-item--active': active,
    }, extraClasses)}
  >
    {name}
    <span className="post-list__filter-item-x"> ✕</span>
  </a>;

PostListFilterItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  extraClasses: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const PostListFilter = ({ filter, setFilter, tags, unsetFilter }) =>
  <div className="post-list__filter">
    {
      tags.length < 1
      ? 'No filter available'
      : 'Filter by tag:'
    }
    {tags.map(({ name }, i) =>
      <PostListFilterItem
        name={name}
        key={i}
        onClick={setFilter}
        active={filter.indexOf(name) > -1}
      />
    )}
    {
      filter.length < 1 ? null :
      <PostListFilterItem
        name="Unset all"
        onClick={unsetFilter}
        active
        extraClasses="post-list__filter-item--unset-button"
      />
    }
  </div>;

PostListFilter.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilter: PropTypes.func.isRequired,
  unsetFilter: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(postTagPropType),
};

export default PostListFilter;
