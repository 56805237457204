/* global document, window */
import jQuery from "jquery";
import Hero from "./modules/hero";
import Faqs from "./modules/faqs";
import PostList from "./modules/postlist";
import Gallery from "./modules/gallery";
import Nav from "./modules/nav";
import Counter from "./modules/counter";
import Carousel from "./modules/carousel";
import FactsCarousel from "./modules/facts-carousel";
import Contacts from "./pages/contacts";
import EventsCalendar from "./modules/events-calendar";
import BackToTop from "./modules/back-to-top";
import Grid from "./modules/grid";
import Navigation from "./navigation";
import ColumnItems from "./modules/column-items";
import AddOns from "./modules/add-ons";
import FacebookFeed from "./modules/facebook-feed";
import Sharer from "./modules/sharer";
import TechnicalSpecification from "./modules/technical-specification";
import "./polyfills/array.from";
import "./polyfills/array.forEach";
import "./polyfills/promise";

// Fire modules based on page/template/post-type
(() => {
  const ND = {
    // All pages
    common: {
      init: () => {
        // JavaScript to be fired on all pages
        Nav();
        Hero();
        Gallery();
        Carousel();
        PostList();
        Counter();
        FactsCarousel();
        EventsCalendar();
        Contacts();
        BackToTop(document, window);
        Navigation(jQuery);
        Grid(jQuery);
        ColumnItems();
        AddOns();
        FacebookFeed();
        Sharer();
        TechnicalSpecification(jQuery);
      },
      finalize: () => {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    "single-products": {
      init: () => {},
    },
    "page-template-template-faq-archive": {
      init: () => {
        Faqs();
      },
    },
    "post-type-archive-faqs": {
      init: () => {
        Faqs();
      },
    },
    "post-type-archive-hedson_contacts": {
      init: () => {},
      finalize: () => {},
    },
  };

  // Fire all common scripts, followed by the page/template specific scripts.
  const UTIL = {
    fire: (func, funcname, args) => {
      let fire;
      const namespace = ND;
      const functionName = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][functionName] === "function";
      if (fire) {
        namespace[func][functionName](args);
      }
    },
    loadEvents: () => {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      Array.from(document.body.classList).forEach((className) => {
        UTIL.fire(className);
        UTIL.fire(className, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };
  document.addEventListener("DOMContentLoaded", () => UTIL.loadEvents());
})();
