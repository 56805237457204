import React, { PropTypes } from 'react';
import classnames from 'classnames';

const FaqLinks = ({ data, filteredData }) => (
  <div className="faq-links">
    {
      data.map((category, i) => (
        <div
          className={classnames({
            'faq-links__link': true,
            'faq-links__link--disabled': filteredData.filter(cat => cat.id === category.id).length < 1,
          })}
          key={i}
        >
          <a href={`#${category.slug}`}>{category.name}</a>
        </div>
      ))
    }
  </div>
);

FaqLinks.propTypes = {
  data: PropTypes.array,
  filteredData: PropTypes.array,
};

export default FaqLinks;
