import React, { PropTypes } from 'react';
import postTagPropType from '../../prop-types/post-tag';
import PostImage from './PostImage';
import PostContent from './PostContent';
import PostTags from './PostTags';

const PostListKnowledgeCenterItem = ({ post, setFilter }) =>
  <article
    className={`
      post-list__item
      ${post.tags.map(({ name }) => `post-list__item--filter-${name.replace(/ /g, '-')}`).join(' ')}
    `}
  >
    <div className="post-list__item-inner">
      {
        post.image && post.image.url
        ? <PostImage title={post.title} image={post.image.url} />
        : null
      }
      <div className="post-list__text-content-wrapper">
        <PostContent
          post={{
            title: post.title,
            text: post.text,
          }}
        />
        {
          !post.files
          ? null
          :
          post.files.map(({ description, file: { url, filename } }, i) =>
            <a
              key={i}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              download
              className="button button--link post-list__download-button"
            >Download</a>
          )
        }
        <footer className="post-list__footer">
          <PostTags tags={post.tags} setFilter={setFilter} />
        </footer>
      </div>
    </div>
  </article>;

PostListKnowledgeCenterItem.propTypes = {
  post: PropTypes.shape({
    image: PropTypes.oneOfType([
      PropTypes.shape({
        url: PropTypes.string,
      }),
      PropTypes.bool,
    ]),
    title: PropTypes.string,
    author: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
    preview_image: PropTypes.shape({
      url: PropTypes.string,
    }),
    postTagPropType: PropTypes.arrayOf(postTagPropType),
    file: PropTypes.oneOfType([
      PropTypes.shape({
        url: PropTypes.string,
      }),
      PropTypes.bool,
    ]),
    date: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  }),
  setFilter: PropTypes.func,
};

export default PostListKnowledgeCenterItem;
