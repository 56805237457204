import throttle from 'lodash.throttle';

const toggleBtnState = (btn, window) => () => window.scrollY > 500
? btn[0].classList.add('site-footer__back-to-top--visible')
: btn[0].classList.remove('site-footer__back-to-top--visible');

export default (document, window) => {
  const btn = document.querySelectorAll('.site-footer__back-to-top');
  return btn.length < 1 ? true : [
    document.addEventListener('scroll',
      throttle(toggleBtnState(btn, window), 500),
    ),
    btn[0].addEventListener('click', () => window.scroll(0, 0)),
  ];
};
