/* global document */
import Flickity from 'flickity';

const FactsCarousel = (el) => {
  const instance = new Flickity(el, {
    adaptiveHeight: true,
    cellSelector: '.facts-carousel__slide',
    wrapAround: true,
  });

  instance.on('select', () => {
    if (instance.selectedIndex % 2) {
      el.classList.remove('facts-carousel__slides--odd');
    } else {
      el.classList.add('facts-carousel__slides--odd');
    }
  });
  el.classList.add('facts-carousel__slides--odd');
  return instance;
};

export default () => {
  Array.from(document.querySelectorAll('.facts-carousel__slides')).forEach((el) => {
    FactsCarousel(el);
  });
};
