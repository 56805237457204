/* global SITE_URL, fetch */
import 'whatwg-fetch';
import * as queryString from 'query-string';

// FIXME This won't work if site is not in root dir!
const route = `${SITE_URL}/wp-json/hedson/v1/`;

const get = (endpoint, params) =>
  fetch(`${route}${endpoint}?${queryString.stringify(params)}`)
  .then(response => response.json());

const post = (endpoint, params = {}) =>
  fetch(route + endpoint, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(params),
  })
  .then(response => response.json());

export default {
  get,
  post,
};
