import { PropTypes } from 'react';
import postTagPropType from './post-tag';

export default PropTypes.shape({
  image: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
  text: PropTypes.string,
  description: PropTypes.string,
  preview_image: PropTypes.shape({
    url: PropTypes.string,
  }),
  file: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  tags: PropTypes.arrayOf(postTagPropType),
  time: PropTypes.string,
  url: PropTypes.string,
});
