import React from 'react';
import postPropType from '../../prop-types/post';
import PostImage from './PostImage';
import PostContent from './PostContent';

const PostListNewsletterItem = ({ post }) => (
  <article
    className={`
      post-list__item
      ${post.tags.map(({ name }) => `post-list__item--filter-${name.replace(/ /g, '-')}`).join(' ')}
    `}
  >
    <div className="post-list__item-inner">
      <PostImage
        title={post.title}
        image={post.image}
        url={post.url}
        target="_blank"
      />
      <div className="post-list__text-content-wrapper">
        <PostContent
          post={{
            title: post.title,
            text: post.text,
            url: post.url,
          }}
          target="_blank"
        />
        {
          !post.url ? null
          :
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={post.url}
            className="button button--link post-list__download-button"
          >Go to newsletter</a>
        }
      </div>
    </div>
  </article>
);

PostListNewsletterItem.propTypes = { post: postPropType };

export default PostListNewsletterItem;
