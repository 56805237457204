/* global document */
import React from 'react';
import ReactDOM from 'react-dom';
import PostListContainer from './PostListContainer';

export default () => {
  const components = Array.from(document.querySelectorAll('.post-list'));
  components.forEach((component) => {
    const typeId = parseInt(component.getAttribute('data-type-id'), 10);
    const tagIds = component.getAttribute('data-tag-ids');
    const typeSlug = component.getAttribute('data-type-slug');
    const pageSize = parseInt(component.getAttribute('data-page-size'), 10);
    const postType = component.getAttribute('data-post-type');
    const showFilter = (component.getAttribute('data-show-filter') === 'true');
    ReactDOM.render(<PostListContainer postType={postType} typeSlug={typeSlug} pageSize={pageSize} tagIds={tagIds} typeId={typeId} showFilter={showFilter} />, component);
  });
};
