/* global document, google, SITE_URL */
import React, { PropTypes } from 'react';

class ContactsMap extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.markers = [];
    this.map = new google.maps.Map(this.mapEl, {
      center: { lat: 0, lng: 0 },
      zoom: 2,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
    });
    this.offsetEl = document.querySelector('.find-contacts__panel');

    this.addMarkers(this.props.contacts);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contacts.map(c => c.id).join() !== this.props.contacts.map(c => c.id).join()) {
      this.removeAllMarkers();
      if (nextProps.contacts.length) {
        this.addMarkers(nextProps.contacts);
      } else {
        this.map.setZoom(2);
      }
    }
  }

  addMarkers(contacts) {
    const bounds = new google.maps.LatLngBounds();
    contacts.filter(contact => contact.map && contact.map.lat !== null && contact.map.lng !== null)
    .forEach((contact) => {
      const { lat, lng } = contact.map;
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        icon: {
          url: `${SITE_URL}/wp-content/themes/hedson/dist/images/map-marker.png`,
          size: new google.maps.Size(62, 31),
          scaledSize: new google.maps.Size(31, 16),
          anchor: new google.maps.Point(16, 8),
        },
        map: this.map,
      });
      marker.addListener('click', () => {
        this.map.setCenterWithOffset(
          marker.getPosition(),
          this.offsetEl.getBoundingClientRect().width / 2,
          0,
        );
        this.props.onContactClick(contact);
      });
      bounds.extend(marker.getPosition());
      this.markers.push(marker);
    });
    this.map.fitBounds(bounds);
    // if bounds are too zoomed in, set zoom to 16
    const idleListener = google.maps.event.addListener(this.map, 'idle', () => {
      if (this.map.getZoom() > 16) {
        this.map.setZoom(16);
      }
      google.maps.event.removeListener(idleListener);
    });
  }

  removeAllMarkers() {
    this.markers.forEach((marker) => {
      google.maps.event.clearListeners(marker, 'click');
      marker.setMap(null);
    });
  }

  render() {
    return (
      <div ref={(c) => { this.mapEl = c; }} className="find-contacts__map">
        <div>map</div>
      </div>
    );
  }
}

ContactsMap.propTypes = {
  contacts: PropTypes.array,
  onContactClick: PropTypes.func,
};

ContactsMap.defaultProps = {
  contacts: [],
};

export default ContactsMap;
