/* global document */
import React from 'react';
import ReactDOM from 'react-dom';
import FaqsContainer from './FaqsContainer';

export default () => {
  const container = document.querySelector('.faq-component');
  if (container) {
    ReactDOM.render(<FaqsContainer />, container);
  }
};
