import $ from "jquery";
import "jquery-match-height";

export default () => {
  // $(".cff-post-text").matchHeight();
  setTimeout(() => $(".fts-jal-single-fb-post").matchHeight(), 50);
  // setTimeout(() => $(".cff-item").matchHeight(), 50);
  // $(".cff-post-links > a, .cff-share-container > a")
  //   .addClass("button")
  //   .addClass("button--link");
};
