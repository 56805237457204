/* global document, window */
import Flickity from "flickity";
import scrollTo from "../../utils/scrollto";
import getCoords from "../../utils/offset";

const createPopupElements = () => {
  const popup = document.createElement("div");
  const imageContainer = document.createElement("div");
  const captionContainer = document.createElement("div");
  const caption = document.createElement("div");
  const image = document.createElement("img");
  const backButton = document.createElement("button");
  backButton.innerText = "Back";
  captionContainer.className = "image-gallery__caption-container";
  popup.className = "image-gallery__popup";
  imageContainer.className = "image-gallery__popup-image-container";
  caption.className = "image-gallery__caption";
  captionContainer.className = "image-gallery__caption-container";
  image.className = "image-gallery__popup-image";
  backButton.className = "button button--primary";
  popup.appendChild(imageContainer);
  imageContainer.appendChild(image);
  popup.appendChild(captionContainer);
  captionContainer.appendChild(caption);
  captionContainer.appendChild(backButton);
  document.body.appendChild(popup);
  return popup;
};

const Gallery = el => {
  const container = el;

  const dismissPopup = () => {
    document.documentElement.classList.remove("image-gallery-open");
  };

  let popup = document.getElementById("image-gallery-popup");
  if (!popup) {
    popup = createPopupElements();
    popup.addEventListener("click", dismissPopup);
  }
  const popupImageEl = popup.querySelector(".image-gallery__popup-image");
  const captionEl = popup.querySelector(".image-gallery__caption");
  const captionContainerEl = popup.querySelector(
    ".image-gallery__caption-container"
  );

  console.log(popupImageEl);

  const instance = new Flickity(el, {
    setGallerySize: false,
    cellSelector: ".image-gallery__item",
    imagesLoaded: true,
    wrapAround: true,
    pageDots: false
  });
  const slides = instance.getCellElements();

  const slideClicked = index => {
    const containerRect = container.getBoundingClientRect();
    const offsetTop = getCoords(container).top;
    let targetY = offsetTop - 50;
    // center view if screen is wide enough for vertical split
    if (window.innerWidth >= 1000) {
      targetY = offsetTop - (window.innerHeight - containerRect.height) / 2;
    }
    scrollTo(targetY).then(() => {
      document.documentElement.classList.add("image-gallery-open");
      captionContainerEl.scrollTop = 0;
      captionEl.scrollTop = 0;
      const selectedImage = slides[index].children[0];
      const image = slides[index].querySelector("img");
      const imageRect = selectedImage.getBoundingClientRect();
      const caption = slides[index].querySelector(
        ".image-gallery__hidden-caption"
      );
      const title = slides[index].getAttribute("data-title");
      if (title) {
        captionEl.innerHTML = `<h3>${title}</h3>`;
      }
      captionEl.innerHTML += caption.innerHTML;
      popupImageEl.src = image.src;
      popupImageEl.style.transition = "none";
      popupImageEl.classList.remove("image-gallery__popup-image--active");
      popupImageEl.style.top = `${imageRect.top + imageRect.height / 2}px`;
      popupImageEl.style.left = `${imageRect.left + imageRect.width / 2}px`;
      popupImageEl.style.maxWidth = `${imageRect.width}px`;
      popupImageEl.style.maxHeight = `${imageRect.height}px`;
      setTimeout(() => {
        popupImageEl.classList.add("image-gallery__popup-image--active");
        popupImageEl.removeAttribute("style");
      }, 500);
    });
  };

  instance.on("staticClick", (event, pointer, slide, index) => {
    if (slide) {
      slideClicked(index);
    }
  });

  container
    .querySelector(".image-gallery__zoom")
    .addEventListener("click", () => {
      slideClicked(instance.selectedIndex);
    });
};

export default () => {
  Array.from(document.querySelectorAll(".image-gallery__carousel")).forEach(
    el => {
      Gallery(el);
    }
  );
};
