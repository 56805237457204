import React, { PropTypes } from 'react';
import classnames from 'classnames';
import ContactsDetailView from './ContactsDetailView';

const ContactsDetailPanel = ({ contact, unsetContact }) => (
  <div
    className={classnames({
      'find-contacts__panel': true,
      'find-contacts__panel--open': contact !== null,
    })}
  >
    {contact ? <ContactsDetailView contact={contact} unsetContact={unsetContact} /> : null}
  </div>
);

ContactsDetailPanel.propTypes = {
  contact: PropTypes.object,
  unsetContact: PropTypes.func.isRequired,
};

export default ContactsDetailPanel;
