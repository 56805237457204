import React, { PropTypes } from 'react';
import Select from 'react-select';

const ContactsFilterSelect = ({ options, selectedValue, onChange, label }) => (
  <Select
    onChange={onChange}
    className="find-contacts__select"
    value={selectedValue}
    options={options}
    placeholder={label}
  />
);

ContactsFilterSelect.propTypes = {
  options: PropTypes.array,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default ContactsFilterSelect;
