/* global window */
import React from 'react';
import Api from '../../utils/api';
import Contacts from './Contacts';
import getParams from '../../utils/getparams';

const qs = getParams(window.location.search);
const filterFromQueryString = {
  country: qs.country,
  market_segments: qs.market_segment,
  product_segments: qs.product_segment,
};

class ContactsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    return Api.get('contacts')
    .then(result => this.setState({
      loading: false,
      data: result,
    }))
    // TODO output error
    .catch(console.error);
  }

  render() {
    return (
      !this.state.loading ?
        <Contacts data={this.state.data} activeFilter={filterFromQueryString} /> : null
    );
  }
}

export default ContactsContainer;
