import React, { PropTypes } from 'react';
import postPropType from '../../prop-types/post';
import PostImage from './PostImage';
import PostContent from './PostContent';
import PostTags from './PostTags';

const PostListNewsItem = ({ post, setFilter }) => (
  <article
    className={`
      post-list__item
      ${post.tags.map(({ name }) => `post-list__item--filter-${name.replace(/ /g, '-')}`).join(' ')}
    `}
  >
    <div className="post-list__item-inner">
      <PostImage title={post.title} image={post.image} url={post.url} />
      <div className="post-list__text-content-wrapper">
        <PostContent post={post} />
        <footer className="post-list__footer">
          <PostTags tags={post.tags} setFilter={setFilter} />
        </footer>
      </div>
    </div>
  </article>
);

PostListNewsItem.propTypes = {
  post: postPropType,
  setFilter: PropTypes.func.isRequired,
};

export default PostListNewsItem;
