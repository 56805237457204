/* global window, document */
window.requestAnimFrame = (() =>
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function (callback) {
    window.setTimeout(callback, 1000 / 60);
  }
)();

// main function
const scrollToY = (scrollTargetY, scrollSpeed, scrollEasing) => new Promise((resolve) => {
  // scrollTargetY: the target scrollY property of the window
  // speed: time in pixels per second
  // easing: easing equation to use

  const scrollY = window.scrollY || document.documentElement.scrollTop;
  const targetY = scrollTargetY || 0;
  const speed = scrollSpeed || 2000;
  const easing = scrollEasing || 'easeOutSine';
  let currentTime = 0;

  // min time .1, max time .8 seconds
  const time = Math.max(0.1, Math.min(Math.abs(scrollY - targetY) / speed, 0.8));

  const easingEquations = {
    easeOutSine(pos) {
      return Math.sin(pos * (Math.PI / 2));
    },
    easeInOutSine(pos) {
      return (-0.5 * (Math.cos(Math.PI * pos) - 1));
    },
    easeInOutQuint (pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5);
      }
      return 0.5 * (Math.pow((pos - 2), 5) + 2);
    },
  };

  const tick = () => {
    currentTime += 1 / 60;

    const p = currentTime / time;
    const t = easingEquations[easing](p);

    if (p < 1) {
      window.requestAnimFrame(tick);

      window.scrollTo(0, scrollY + ((targetY - scrollY) * t));
    } else {
      resolve();
      window.scrollTo(0, targetY);
    }
  };

  tick();
});

export default scrollToY;
