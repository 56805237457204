/* global window, document */
import React, { PropTypes } from 'react';
import ReactDOM from 'react-dom';
import Isotope from 'isotope-layout';
import { all } from 'ramda';
import Api from '../../utils/api';
import PostList from './PostList';

// Either add or remove the passed item to the array
// addOrRemoveFilter :: [a] -> a -> [a]
const addOrRemoveFilter = (filter, item) =>
filter.indexOf(item) < 0
? [item, ...filter]
: filter.filter(i => i !== item);

class PostListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      pageSize: 12,
      filter: [],
      isotopeGrid: null,
    };
    this.setFilter = this.setFilter.bind(this);
    this.unsetFilter = this.unsetFilter.bind(this);
  }

  componentDidMount() {
    Api.get('posts_list', {
      post_type: this.props.postType,
      type_slug: this.props.typeSlug,
      type_id: this.props.typeId,
      tag_ids: this.props.tagIds,
    }).then(({ posts }) => this.setState({
      loading: false,
      posts,
    }))
    .then(() => {
      const wrapper = ReactDOM.findDOMNode(this).querySelectorAll('.post-list__wrapper')[0];
      return !wrapper ? true : setTimeout(() => this.setState({
        isotopeGrid: new Isotope(wrapper, {
          itemSelector: '.post-list__item',
        }),
      }), 500);
    });
  }

  setFilter(filterItem) {
    // Toggle the provided filterItem to the current filter list
    const filter = addOrRemoveFilter(this.state.filter, filterItem);
    this.setState({ filter });
    // Update the isotope filter
    return this.state.isotopeGrid.arrange({
      filter: (_, itemElem) => filter.length === 0
      || all(c => itemElem.classList.contains(`post-list__item--filter-${c.replace(/ /g, '-')}`), filter),
    });
  }

  unsetFilter() {
    this.setState({ filter: [] });
    this.state.isotopeGrid.arrange({ filter: () => true });
  }

  render() {
    return (
      this.state.loading
        ?
          <div className="loader"><span>Loading posts, please wait…</span></div>
        :
          <PostList
            posts={this.state.posts}
            loading={this.state.loading}
            setFilter={this.setFilter}
            unsetFilter={this.unsetFilter}
            filter={this.state.filter}
            typeSlug={this.props.typeSlug}
            showFilter={this.props.showFilter}
          />
    );
  }
}

PostListContainer.propTypes = {
  postType: PropTypes.string.isRequired,
  tagIds: PropTypes.string,
  typeId: PropTypes.number.isRequired,
  typeSlug: PropTypes.string.isRequired,
  showFilter: PropTypes.bool,
};

export default PostListContainer;
