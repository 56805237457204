import React, { PropTypes } from 'react';
import classnames from 'classnames';
import { prop, map, compose, flatten, uniqBy } from 'ramda';
import PostListFilter from './PostListFilter';
import PostListNewsItem from './PostListNewsItem';
import PostListImageItem from './PostListImageItem';
import PostListNewsletterItem from './PostListNewsletterItem';
import PostListEventItem from './PostListEventItem';
import PostListPressReleaseItem from './PostListPressReleaseItem';
import PostListKnowledgeCenterItem from './PostListKnowledgeCenterItem';

/* render different ListItem components based on the type slug */
const itemComponents = {
  news: PostListNewsItem,
  images: PostListImageItem,
  newsletters: PostListNewsletterItem,
  events: PostListEventItem,
  hedson_academy: PostListEventItem,
  'press-releases': PostListPressReleaseItem,
  knowledge_center: PostListKnowledgeCenterItem,
};

const getPostTags = compose(
  uniqBy(({ name }) => name),
  flatten,
  map(prop('tags')),
);

class PostList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }

  render() {
    const PostListItem = itemComponents[this.props.typeSlug];
    return (
      <div className="container">
        { this.props.showFilter && getPostTags(this.props.posts).length > 0 ?
          <PostListFilter
            filter={this.props.filter}
            setFilter={this.props.setFilter}
            unsetFilter={this.props.unsetFilter}
            tags={getPostTags(this.props.posts)}
          /> : null
        }
        <div className={`post-list__wrapper post-list-wrapper--${this.props.typeSlug}`}>
          {this.props.posts.map((post, i) => (
            <PostListItem key={i} post={post} setFilter={this.props.setFilter} />
          ),
        )}
        </div>
      </div>
    );
  }
}

PostList.propTypes = {
  setFilter: PropTypes.func.isRequired,
  unsetFilter: PropTypes.func.isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  typeSlug: PropTypes.string.isRequired,
  showFilter: PropTypes.bool,
};

PostList.defaultProps = {
  posts: [],
};

export default PostList;
