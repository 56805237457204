import React, { PropTypes } from 'react';
import moment from 'moment';
import postPropType from '../../prop-types/post';

const PostContent = ({ post, target = '_self' }) =>
  <div>
    {
      !post.title ? null
      : <div className="post-list__title">
        {
          !post.url ? post.title
          : <a target={target} href={post.url}>{post.title}</a>
        }
      </div>
    }
    {
      !post.date && !post.author ? null
      :
      <div className="post-list__meta">
        {
          !post.date ? null
          : <div className="post-list__date">{moment(post.date).format('YYYY-MM-DD')}</div>
        }
        {
          !post.author ? null
          : <div className="post-list__author">{post.author}</div>
        }
      </div>
    }
    {
      !post.text ? null
      :
      <div
        className="post-list__text"
        dangerouslySetInnerHTML={{
          __html: post.text,
        }}
      />
    }
  </div>;

PostContent.propTypes = {
  post: postPropType,
  target: PropTypes.string,
};

export default PostContent;
