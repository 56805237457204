import React, { PropTypes } from 'react';
import Modal from 'react-modal';
import Popup from './Popup';

class Sharer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  handleRequestClose() {
    this.setState({ modalOpen: false });
  }

  handleClick() {
    this.setState({ modalOpen: true });
  }

  render() {
    const { title } = this.props;
    return (
      <div className="sharer">
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            this.handleClick();
          }}
        >
          {title}
        </a>
        <Modal
          isOpen={this.state.modalOpen}
          onRequestClose={this.handleRequestClose}
          contentLabel={title}
          overlayClassName="modal"
          className="modal__content"
        >
          <Popup title={title} />
        </Modal>
      </div>
    );
  }
}

Sharer.propTypes = {
  title: PropTypes.string.isRequired,
};

Sharer.defaultProps = {

};

export default Sharer;
