import React, { PropTypes } from 'react';
import R from 'ramda';

const renderField = (value, key, i) => value
? R.indexOf(key, ['email', 'website', 'email_support', 'email_info']) > -1
  ? <div key={i} className={`hedson-contact__field hedson-contact__field--${key}`}><a href={value}>{value}</a></div>
  : <div key={i} className={`hedson-contact__field hedson-contact__field--${key}`}>{value}</div>
: null;

const ContactsDetailView = ({ contact, unsetContact }) => (
  <div className="hedson-contact">
    <div className="hedson-contact__inner">
      {
        contact.logo
        ? <div className="hedson-contact__logo"><img role="presentation" src={contact.logo} /></div>
        : null
      }
      <h2 className="hedson-contact__title">{contact.title}</h2>
      {contact.free_text && <div dangerouslySetInnerHTML={{__html: contact.free_text }} />}
      <div className="hedson-contact__contact-details">
        {
          Object
          .keys(contact.contact_details)
          .map((key, i) => renderField(contact.contact_details[key], key, i))
        }
        {
          contact.email_support
          ? renderField(contact.email_support, 'email_support', 'email_support')
          : null
        }
        {
          contact.email_info
          ? renderField(contact.email_info, 'email_info', 'email_info')
          : null
        }
      </div>
      <div className="hedson-contact__staff-list">
        {
          contact.staff
          .map(({ details, name }, i) => <div className="hedson-contact__staff" key={i} dangerouslySetInnerHTML={{ __html: `<h2>${name}</h2>${details}` }} />)
        }
      </div>
    </div>
    <a className="hedson-contact__close-button" onClick={unsetContact}>×</a>
  </div>
);

ContactsDetailView.propTypes = {
  contact: PropTypes.object,
  unsetContact: PropTypes.func.isRequired,
};

export default ContactsDetailView;
