import React, { PropTypes } from 'react';
import postTagPropType from '../../prop-types/post-tag';

const PostTags = ({ tags, setFilter }) => !tags || tags.length < 1
  ? null
  :
  <div className="post-list__tags">
    {tags.map(({ name }, i) =>
      <a
        key={i}
        onClick={() => setFilter(name)}
        className="post-list__tag"
      >
        {name}
      </a>
    )}
  </div>;

PostTags.propTypes = {
  tags: PropTypes.arrayOf(postTagPropType),
  setFilter: PropTypes.func.isRequired,
};

export default PostTags;
