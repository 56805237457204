import React, { PropTypes } from 'react';
import FaqItem from './FaqItem';

const FaqList = ({ data }) => (
  <div className="faq-list">
    {data.map((category, i) =>
      (
        <div className="faq-category" key={i} id={category.slug}>
          <div className="faq-container">
            <h3 className="faq-category__name">{category.name}</h3>
            {
              category.posts.map((post, j) => (
                <FaqItem key={j} data={post} />
              ))
            }
          </div>
        </div>
      ),
    )}
    {
      !data.length ?
        <div className="faq-list__message">Your search returned no results.</div>
        : null
    }
  </div>
);

FaqList.propTypes = {
  data: PropTypes.array,
};

export default FaqList;
